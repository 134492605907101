import React from "react";
import charly1 from './img/dogs/charly/charly1.jpg';
import charly2 from './img/dogs/charly/charly2.jpg';
import charly3 from './img/dogs/charly/charly3.jpg';
import charly4 from './img/dogs/charly/charly4.jpg';
import DogsAgremment from "./documents/agreement_dogs.pdf";
import DogsQ from "./documents/questionnaire_dogs.docx";

export class Charly extends React.Component{
    render() {
        return(
            <div className="container-fluid mt-100 mb-50 text-center d-flex">
                <div className="row justify-content-center m-auto">
                    <div className="charly mb-30 col-sm-12">
                        <h3>Чарли</h3>
                    </div>
                    <div className="charly mb-30 col-sm-3">
                        <img className="charly" src={charly1} style={{width:'100%'}} alt="charly1" />
                    </div>
                    <div className="charly mb-30 col-sm-3">
                        <img className="charly2" src={charly2} style={{width:'100%'}} alt="charly2" />
                    </div>
                    <div className="charly mb-30 col-sm-3">
                        <img className="charly3" src={charly3} style={{width:'100%'}} alt="charly3" />
                    </div>
                    <div className="charly mb-30 col-sm-3">
                        <img className="charly4" src={charly4} style={{width:'100%'}} alt="charly4" />
                    </div>
                    <div className="charly mb-30 col-sm-12">
                        <h6>Возраст: 10 лет.</h6>
                        <h6>Рост: 60 см.</h6>
                        <p>Холерик. С энтузиазмом относится к любому предложению, но с трудом сосредотачивается на занятиях и быстро забывает выученные команды.</p>
                        <p>Гиперактивный инфантильный экстраверт, но дома включает энергосберегательный режим.</p>
                        <p>Пищевик на 200%, за вкусняшки готов на всё, попрошайка, поэтому необходимо бдительно следить за весом.</p>
                        <p>При недовольстве может ходить и ворчать. Тем не менее, очень отходчивый и быстро забывает об испытанном дискомфорте. Склонен к эмпатии.</p>
                        <p>Прошёл полную социализацию: прекрасно ездит в машине, адаптирован к городской среде, безупречно соблюдает бытовые правила, на прогулке может тянуть, но хорошо откликается на сигналы поводка.</p>
                        <p>Будущие хозяева должны быть готовы к активным выгулам и иметь хорошую физическую форму, чтобы сдерживать иногда чрезмерную эмоциональность Чарли на улице, в том числе, порой возникающие у него: желание "на ручки" и любовь к бесконечному нырянию в сугробах.</p>
                        <p>Вместе с тем, Чарли не приемлет никакого давления, сразу замыкается в себе или начинает упрямиться, поэтому необходимо уметь с ним спокойно договариваться мягкой силой - он по достоинству оценит вкуснятину, припрятанную в кармане, плавные движения и доброжелательный тон. За заботу и доброе слово отплатит абсолютным доверием.</p>
                        <p>Пристраивается в семью строго без маленьких детей, без других собак - лоялен к сородичам только вне собственной территории. Возможно проживание с ненавязчивыми представителями кошачьих - сам интереса к ним не проявляет, но откровенно боится, если те, к нему пристают. К кошкам, собакам и другим животным вне дома равнодушен.</p>
                        <p>Чарли абсолютно здоров, привит, кастрирован, обработан от всех паразитов. Отдается по <a href={DogsAgremment} download="agreement_dogs.pdf">договору ответственного содержания</a> с ненавязчивым отслеживанием дальнейшей судьбы.</p>
                    </div>
                    <div className="chivas mb-30 col-sm-12">
                        <h6><b>Если Вам понравился Чарли, Вы можете заполнить <a href={DogsQ} download="questionnaire_dogs.docx">Анкету потенциального хозяина</a> и после направить нам её на e-mail <a href="mailto:info@budu-ryadom.ru">info@budu-ryadom.ru</a> с указанием в теме письма его имени</b></h6>
                    </div>
                </div>
            </div>
        )
    }
}