import React from 'react';
import {host} from "../config";
import {Redirect} from "react-router-dom";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import {Link} from "react-router-dom";

export class AskForHelp extends React.Component{

    constructor(props) {
        super(props);
        this.sunEditorRef = React.createRef();
        this.state = {
            title: "",
            name: "",
            tel: "",
            email: "",
            text: "",
            redirect: false
        }
        this.handlerInput = this.handlerInput.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }



    handlerInput(event){
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        })
    }

    handleChange(event){
        this.setState({title: event.target.value});
    }

    handlerSubmit(event){
        event.preventDefault();
        const formData = new FormData();
        formData.append("name", this.state.name);
        formData.append("tel", this.state.tel);
        formData.append("email", this.state.email);
        formData.append("text", this.state.text);
        formData.append("title", this.state.title);
        fetch(host+"php/handlerHelp.php",{
            method: "POST",
            body: formData
        }).then(response=>response.json())
            .then(result=>{
                this.setState({
                    redirect: true
                })
            })
    }

    render() {
        const redirect = this.state.redirect;
        if(redirect){
            return <Redirect to="/" />
        }else {
            return (
                <section className="mt-100">
                    <h6 className="text-center">ОСНОВАНИЯМИ ДЛЯ ПРИНЯТИЯ РЕШЕНИЙ ОБ ОКАЗАНИИ АДРЕСНОЙ ПОМОЩИ БЛАГОПОЛУЧАТЕЛЯМ ЯВЛЯЮТСЯ:</h6>
                    <p className="text-center">
                        <li>Заявка на получение помощи;</li>
                        <li>Контактные данные: телефон, e-mail, контактное лицо;</li>
                    </p>
                    <h5 className="mb-10 text-center"><b>Пожалуйста, заполните заявку на получение помощи:</b></h5>
                    <div className="container-fluid text-left d-flex">
                        <div className="row">
                            <div className="col-lg-3 home-about-left no-padding ml-50">
                                <div className="form-group">
                                    <form onSubmit={this.handlerSubmit} className="form-area">
                                        <h6 className="name" style={{color:"black"}}>Имя</h6>
                                        <input value={this.state.name} onChange={this.handlerInput} name="name" type="text" className="form-control mb-20" placeholder="Имя" style={{width:"250px"}}/>
                                        <h6 className="phone" style={{color:"black"}}>Телефон</h6>
                                        <input value={this.state.tel} onChange={this.handlerInput} name="tel" type="tel" className="form-control mb-20" placeholder="+7(___)___-__-__" style={{width:"250px"}}/>
                                        <h6 className="email" style={{color:"black"}}>E-mail</h6>
                                        <input value={this.state.email} onChange={this.handlerInput} name="email" type="email" className="form-control mb-20" placeholder="Email" style={{width:"250px"}}/>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-8 home-about-left no-padding ml-20">
                                <div className="form-group">
                                    <form onSubmit={this.handlerSubmit} className="form-area">
                                        <h6 className="title mb-1" style={{color:"black"}}>Тема</h6>
                                        <select value={this.state.title} onChange={this.handlerInput} name="title" className="form-control mb-10">
                                            <option name="findPet" value="findPet">Нашли потеряшку</option>
                                            <option name="lostPet" value="lostPet">Поиск потеряшки</option>
                                            <option name="findHome" value="findHome">Пристройство</option>
                                            <option name="rescue" value="rescue">Спасение с улицы</option>
                                            <option name="violence" value="violence">Жестокое обращение</option>
                                            <option name="feed" value="feed">Нужен корм</option>
                                            <option name="medical" value="medical">Нужны лекарства</option>
                                            <option name="veterinary" value="veterinary">Оплата ветуслуг</option>
                                            <option name="foster" value="foster">Оплата передержки</option>
                                            <option name="castrate" value="castrate">Стерилизация</option>
                                        </select>
                                        <h6 className="details" style={{color:"black"}}>Комментарии</h6>
                                        <li>Если нужен корм, укажите: вид животных, возраст, спецдиету (при наличии), вид корма (влажный/сухой) и необходимое количество;</li>
                                        <li>Если нужны лекарства, укажите: вес животного, количество необходимого препарата и его дозировку</li>
                                        <li>Если речь о финансовой помощи, опишите ситуацию и будьте готовы предоставить документы, подтверждающие её необходимость. Также вам понадобятся чеки и отчёты о потраченных средствах.</li>
                                        <div className="mb-3">
                                            <SunEditor
                                                ref={this.sunEditorRef}
                                                name="text"
                                                setOptions={
                                                    {
                                                        height: 300,
                                                        buttonList : [
                                                            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                                            ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                                            ['link', 'fullScreen', 'preview', 'save']
                                                        ]
                                                    }
                                                }
                                                onChange={(value)=>{
                                                    const name = (this.sunEditorRef.current.props.name);
                                                    this.setState({
                                                        [name]: value
                                                    })
                                                }}
                                                placeholder="Пожалуйста, подробно опишите ситуацию"
                                                />
                                        </div>
                                        <div className="privacy">
                                            <p>
                                                <b>Нажимая на кнопку, Вы даете согласие на <Link to="/privacy"><u>обработку своих персональных данных</u></Link></b>
                                            </p>
                                        </div>
                                        <div className="col-sm-3.5 d-flex ml-auto mb-20 mt-20">
                                            <input type="submit" className="form-control btn genric-btn primary" style={{borderRadius:"40px", height:"50px", width:"200px"}} value="Отправить сообщение"/>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        }
    }
}