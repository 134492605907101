import React from "react";
import svane1 from './img/cats/svane/svane_1.JPG';
import svane2 from './img/cats/svane/svane_2.JPG';
import svane3 from './img/cats/svane/svane_3.JPG';
import svane4 from './img/cats/svane/svane_4.JPG';
import CatsAgremment from './documents/agreement_cats.pdf';
import CatsQ from './documents/questionnaire_cats.docx';


export class Svane extends React.Component{
    render() {
        return(
            <div className="container-fluid mt-100 mb-50 text-center d-flex">
                <div className="row justify-content-center m-auto">
                    <div className="svane mb-30 col-sm-12">
                        <h3>Свейнт</h3>
                    </div>
                    <div className="svane mb-30 col-sm-3">
                        <img className="svane1" src={svane1} style={{width:'100%'}} alt="svane1" />
                    </div>
                    <div className="svane mb-30 col-sm-3">
                        <img className="svane2" src={svane2} style={{width:'100%'}} alt="svane2" />
                    </div>
                    <div className="svane mb-30 col-sm-3">
                        <img className="svane3" src={svane3} style={{width:'100%'}} alt="svane3" />
                    </div>
                    <div className="svane mb-30 col-sm-3">
                        <img className="svane4" src={svane4} style={{width:'100%'}} alt="svane4" />
                    </div>
                    <div className="svane mb-30 col-sm-12">
                        <h6>Возраст: 5 лет.</h6>
                        <p>
                            «У лукоморья дуб зелёный;<br />
                            Златая цепь на дубе том:<br />
                            И днём и ночью кот учёный<br />
                            Всё ходит по цепи кругом;<br />
                            Идёт направо — песнь заводит,<br />
                            Налево — сказку говорит…»<br />
                            (с) А.С. Пушкин
                        </p>
                        <p>Свейн как будто тот самый кот, который прямиком из поэмы Александра Сергеевича Пушкина «Руслан и Людмила» отправился в путешествие в наш мир.</p>
                        <p>В Свейне идеально всё – от шикарной внешности с роскошными усами до необыкновенно ласкового характера.</p>
                        <p>Свейн – мечта, а не кот. Большой, добрый, умный, благодарный и благородный.</p>
                        <p>Только представьте, как к вам, уставшим после рабочего дня, запрыгивает на колени ОН и запевает свою успокаивающая песнь. Умиротворение обеспеченно моментально!</p>
                        <p>Свейн уже не котенок, а значит – детских шалостей в виде подранных штор ждать не придется. Он чистоплотен и прекрасно знает лоток. Когти точит исключительно об когтеточку. Настоящий учённый кот, только что книжек в слух не читает.</p>
                        <p>Не конфликтен и прекрасно уживется с сородичами. Да и в семью с детьми отличная партия – очень терпелив, когти в игре выпускать не станет, а при недовольстве предпочтёт дипломатично уйти, пока все не успокоятся.</p>
                        <p>Свейн здоров, привит, кастрирован, обработан от всех паразитов. Отдаётся по <a href={CatsAgremment} download="agreement_cats.pdf">договору ответственного содержания</a> с ненавязчивым отслеживанием дальнейшей судьбы.</p>
                    </div>
                    <div className="leya mb-30 col-sm-12">
                        <h6><b>Если Вам понравился Свейн, Вы можете заполнить <a href={CatsQ} download="questionnaire_cats.docx">Анкету потенциального хозяина</a> и после направить нам её на e-mail <a href="mailto:info@budu-ryadom.ru">info@budu-ryadom.ru</a> с указанием в теме письма "Котик Свейн"</b></h6>
                    </div>
                </div>
            </div>
        )
    }
}