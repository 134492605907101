import React from 'react';
import {Helmet} from "react-helmet";

export class Widget extends React.Component{
    render() {
        return(
            <section className="payment-page-area section-gap">
                <div className="container-fluid text-left d-flex">
                    <div className="row">
                        <div className="col-lg-12">
                            <React.Fragment>
                                <Helmet>
                                    <script src={"https://widgets.donation.ru/widgets/7401860b-8015-425b-815a-fc0195cf547d/widget.js"} async="true"></script>
                                </Helmet>
                            </React.Fragment>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}