import React from "react";
import phone7715 from "./img/Phone7715.png";
import megafon from "./img/megafon.png";
import mts from "./img/mts.png";

export class Number7715 extends React.Component{
    render() {
        return (
            <section className="mt-100">
                <div className="container-fluid text-center d-flex">
                    <div className="row align-items-center">
                        <div className="col-sm-9 home-about-left">
                            <h5><b>УСЛУГА ВРЕМЕННО ПРЕДОСТАВЛЯЕТСЯ ТОЛЬКО АБОНЕНТАМ МЕГАФОН И МТС</b></h5>
                            <h5><i>При оплате с помощью короткого номера 7715</i></h5>
                            <p>Отправьте SMS-сообщение на номер <b style={{color:"black"}}>7715</b> с ключевым словом <b style={{color:"black"}}>«ВИЛЯЙ»</b> и через пробел укажите цифрами сумму пожертвования в рублях.<br />
                                Например: <b style={{color:"black"}}>ВИЛЯЙ 100</b>
                            </p>
                            <p>
                                <i>При отправке SMS на номер <b style={{color:"black"}}>7715</b> стоимость сообщения оплачивается абонентом в порядке, установленном операторами сотовой связи.</i>
                            </p>
                        </div>
                        <div className="col-lg-3 home-about-right no-padding">
                            <img className="phone" src={phone7715} style={{width:'100%'}} alt="phone"/>
                        </div>
                        <div className="col-sm-9 home-about-left">
                            <p>
                                <i>
                                    После отправки SMS абонент получает сообщение от своего сотового оператора с просьбой подтвердить платеж: <br />
                                    для абонентов Мегафон с номера <b style={{color:"black"}}>3-33</b>, МТС - <b style={{color:"black"}}>1212</b>. <br />
                                    Подтверждение осуществляется бесплатной ответной SMS (согласно полученной от оператора инструкции).
                                </i>
                            </p>
                            <p>
                                <i>После списания денежных средств от сотового оператора приходит SMS об успешном платеже (Мегафон - <b style={{color:"black"}}>3-33</b>, МТС - <b style={{color:"black"}}>MTS.dengi</b>), а также с номера <b style={{color:"black"}}>7715</b>.</i>
                            </p>
                            <p>
                                <b>ВНИМАНИЕ:</b> В случае отправки SMS <b style={{color:"black"}}>без указания суммы пожертвования</b> после префикса благополучателя, в ответ приходит SMS-запрос на списание со счета телефона <b style={{color:"black"}}>фиксированной суммы 45 р.</b>
                            </p>
                            <h6><b>Стоимость отправки SMS на номер 7715</b></h6>
                            <p>
                                <li>для абонентов «МегаФон» <b>0 ₽</b></li>
                                <li>для абонентов «МТС» <b>0 ₽ + 10 руб. за каждый успешный платеж</b></li>
                            </p>
                            <h6 className="mb-10 mt-20"><b>УСЛОВИЯ ОПЕРАТОРОВ</b></h6>
                            <p>
                                <img className="megafon" src={megafon} style={{width:'35px'}} alt="megafon" /> <u style={{color:"black"}}><b style={{color:"black"}}>Для абонентов «МегаФон»:</b></u>
                                <li>Услуга доступна всем абонентам «МегаФона», за исключением юридических лиц и абонентов, обслуживающихся по кредитной системе расчетов;</li>
                                <li>Услугой нельзя воспользоваться при финансовой блокировке номера;</li>
                                <li>После списания суммы пожертвования на счете должно оставаться не менее 10 ₽;</li>
                                <li>Все расчеты при пользовании услугой осуществляются в рублях;</li>
                                <li>Минимальная сумма единовременного платежа 1 ₽;</li>
                                <li>Максимальная сумма единовременного платежа 5 000 ₽;</li>
                                <li>Максимальная сумма платежей в сутки - 40 000 ₽;</li>
                                <li>Максимальная сумма платежей в месяц - 40 000 ₽;</li>
                                <li><a href="https://moscow.megafon.ru/download/~federal/oferts/oferta_m_platezhi.pdf" >Договор оферты Мегафон</a>;</li>
                                <li>Пользуясь услугой «Мобильные платежи», вы соглашаетесь с условиями ее предоставления.</li>
                            </p>
                            <p>
                                <img className="mts" src={mts} style={{width:'70px'}} alt="mts" /> <u style={{color:"black"}}><b style={{color:"black"}}>Для абонентов «МТС»:</b></u>
                                <li>контракт с МТС должен быть оформлен на физическое лицо (на тарифном плане «Супер Ноль» данная услуга не предоставляется);</li>
                                <li>минимальная сумма платежа – 1 ₽;</li>
                                <li>максимальная сумма одного платежа не более 14 999 ₽;</li>
                                <li>количество платежей в сутки – не более 5 платежей;</li>
                                <li>максимальное кол-во платежей за неделю - 20;</li>
                                <li>максимальная сумма платежей за сутки – не более 30 000 ₽;</li>
                                <li>максимальная сумма платежей за месяц – не более 40 000 ₽;</li>
                                <li>обязательный остаток на лицевом счете после выполнения платежа (т.н. «неснижаемый остаток») – не менее 10 ₽;</li>
                                <li>ограничения по незавершенным операциям (новый СМС-запрос не может быть сделан, если предыдущий еще не обработан);</li>
                                <li>на абонентском номере должны отсутствовать опция «Запрет возврата части аванса» и/или «Запрет передачи данных третьим лицам информации об абоненте»;</li>
                                <li>невозможно использовать кредитные и бонусные средства, а также средства, начисленные по рекламным акциям, скидки, на услуги связи, предоставленные МТС, скидку на первоначальный объем услуг, предоставляемую при заключении договора (покупке комплекта) и т.п.;</li>
                                <li><a href="https://static.mts.ru/uploadmsk/contents/1655/soglashenie_easy_pay.pdf">Договор оферты МТС</a></li>
                            </p>
                            <p>Мобильные платежи осуществляются через <a href="http://sms7715.ru/"><i>Национальный благотворительный фонд</i></a></p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}