import React from "react";
import {Helmet} from 'react-helmet';

export class RecurringPayments extends React.Component{

    render() {
        return(
            <section className="payment-page-area section-gap">
                <div className="container-fluid text-left d-flex">
                    <div className="row" >
                        <div className="col-lg-12">
                            <div><h3>Подписаться на ежемесячное автопожертвование</h3></div>
                            <mixplat-form>
                                <React.Fragment>
                                    <Helmet>
                                        <script src={"https://widgets.donation.ru/forms/5eaa5e85-0da1-449b-8c3e-20ceac079ef0/form.js"} async='true'></script>
                                    </Helmet>
                                </React.Fragment>
                            </mixplat-form>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}