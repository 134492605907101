import React from "react";
import nfa1 from "./img/nfa/nfa_page_1.jpg";
import nfa2 from "./img/nfa/nfa_page_2.jpg";
import nfa3 from "./img/nfa/nfa_page_3.jpg";

export class NFA extends React.Component{
    render() {
        return (
            <div className="container-fluid mt-100 text-center d-flex">
                <div className="flex-row justify-content-center px-3 m-auto">
                    <div className="nfa mb-30 col-sm-10">
                        <h3>Письмо-подтверждение о не включении Благотворительного фонда в Реестр некоммерческих организаций, выполняющих функции иностранного агента</h3>
                    </div>
                    <div className="nfa-pages col-sm-12">
                        <img className="nfa1" src={nfa1} style={{width:'100%'}} alt="page1"/>
                        <img className="nfa2" src={nfa2} style={{width:'100%'}} alt="page2"/>
                        <img className="nfa3" src={nfa3} style={{width:'100%'}} alt="page3"/>
                    </div>
                </div>
            </div>
        )
    }
}