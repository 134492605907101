import React from "react";
import leo1 from './img/dogs/leo/leo1.jpeg';
import leo2 from './img/dogs/leo/leo2.jpeg';
import leo3 from './img/dogs/leo/leo3.jpeg';
import leo4 from './img/dogs/leo/leo4.jpeg';
import DogsAgremment from "./documents/agreement_dogs.pdf";
import DogsQ from "./documents/questionnaire_dogs.docx";

export class Leo extends React.Component{
    render() {
        return(
            <div className="container-fluid mt-100 mb-50 text-center d-flex">
                <div className="row justify-content-center m-auto">
                    <div className="leo mb-30 col-sm-12">
                        <h3>Лео</h3>
                    </div>
                    <div className="leo mb-30 col-sm-3">
                        <img className="leo1" src={leo1} style={{width:'100%'}} alt="leo1" />
                    </div>
                    <div className="leo mb-30 col-sm-3">
                        <img className="leo2" src={leo2} style={{width:'100%'}} alt="leo2" />
                    </div>
                    <div className="leo mb-30 col-sm-3">
                        <img className="leo3" src={leo3} style={{width:'100%'}} alt="leo3" />
                    </div>
                    <div className="leo mb-30 col-sm-3">
                        <img className="leo4" src={leo4} style={{width:'100%'}} alt="leo4" />
                    </div>
                    <div className="leo mb-30 col-sm-12">
                        <h6>Возраст: 5 лет.</h6>
                        <h6>Рост: 60 см.</h6>
                        <p>Лео - эталон настоящего мужчины. Статный, атлетично сложенный, холенный – и это лишь внешние качества. Под ними скрывается огромное сердце, спокойный, покладистый нрав, уравновешенность, мудрость и доброта.</p>
                        <p>Рядом с Лео чувствуется уверенность и безопасность. И в тоже время, в нем сохранился дух щенка. Он с радостью составляет компанию в игре, забавно тычется большим кожаным носом в руку и облизывает лицо. Ещё он обожает просто встать и прижаться к ноге, и, поглаживая его, Вы и не заметите, что этот "легкий" медвежонок уже полностью улегся на Вас. А как же он любит подставлять пузико!</p>
                        <p>Кстати, Лео абсолютно дружелюбен и прекрасно ладит со всеми. А ещё он оказался очень трепетной нянькой, обожающей детей.</p>
                        <p>Лео приучен к 2х разовому выгулу, замечательно ездит в машине, спокойно остается дома один, обладает охранными качествами на своей территории. Из особенностей поведения – разговорчив.</p>
                        <p>Если Вы ищите надежного друга и верного товарища, с которым не страшно ходить по тёмным улицам – Лео именно тот, кто Вам нужен.</p>
                        <p>Лео абсолютно здоров, привит, кастрирован, обработан от паразитов. Пристраивается по <a href={DogsAgremment} download="agreement_dogs.pdf">договору ответственного содержания</a> с ненавязчивым отслеживанием дальнейшей судьбы.</p>
                    </div>
                    <div className="chivas mb-30 col-sm-12">
                        <h6><b>Если Вам понравился Лео, Вы можете заполнить <a href={DogsQ} download="questionnaire_dogs.docx">Анкету потенциального хозяина</a> и после направить нам её на e-mail <a href="mailto:info@budu-ryadom.ru">info@budu-ryadom.ru</a> с указанием в теме письма его имени</b></h6>
                    </div>
                </div>
            </div>
        )
    }
}