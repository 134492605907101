import React from 'react';
import {host} from "../config";
import {Link, Redirect} from "react-router-dom";
import {FaVk} from "react-icons/fa";
import {FaTelegramPlane} from "react-icons/all";

function WritePost(blog) {
    return (
        <div >
            {/*  <Link to={`/OnePost/${blog.id}`}> */}
            <Link to={`/post/${blog.id}`}>
                    <a class="posts-title">
                        <h3>{blog.title}</h3>
                    </a>
                <p class="excert">{blog.text}</p>
                    <a href={`/post/${blog.id}`} class="primary-btn">Продолжить чтение</a>
            </Link>
        </div>
    )
}
export class Articles extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            indiv: []
        }
    }

    componentDidMount() {
        console.log("add");
        fetch(host+"/getPosts")
            .then(result=> {
                return result.json()
            }).then(
                result=>{
                    let postsindiv = [];
                    for(let i = 0; i < result.length; i++){
                        postsindiv.push(<WritePost
                            id = {result[i].id}
                            title = {result[i].title}
                            text = {result[i].text.substr(0, 240)}

                        />);
                       //console.log(postsindiv[i]);
                    }
                    this.setState({
                        indiv: postsindiv
                    })
            })
    }

    render(){
        return(
            <section class="mt-100">
                <div className="container-fluid text-center d-flex">
                    <div className="row align-items-center">
                        <div className="col-sm-5">
                            <h4>Новости фонда</h4>
                            <a href="https://vk.com/charityfund_budu_ryadom"><FaVk size='30' style={{color:'#FAB700'}}/></a>
                            <a href="https://web.telegram.org/z/#-1737265996"><FaTelegramPlane size='30' color='#FAB700'/></a>
                        </div>
                        <div className="col-sm-7 mt-3">
                            <h4>Полезная информация</h4>
                            <Link to="/vocabulary"><h6><b><u>Словарик помогающего</u></b></h6></Link>
                            <Link to="/inheritance"><h6><b><u>Если бы они оставляли завещание…</u></b></h6></Link>
                            <Link to="/mainlow">
                                <h6>
                                    <b>
                                        <u>
                                            Выдержки из Федерального закона «Об ответственном обращении с животными и о внесении изменений в
                                            отдельные законодательные акты Российской Федерации» от 27.12.2018 N 498-ФЗ
                                        </u>
                                    </b>
                                </h6>
                            </Link>
                        </div>
                        <div className="col-sm-12 mt-4">
                            <h4>Статьи</h4>
                            <p>Раздел на реконструкции</p>
                            {this.state.indiv}
                        </div>
                    </div>
                </div>
            </section>
                )
    }
}