import React from "react";
import tarc from './img/tarc/tarc.jpg';

export class Tarc extends React.Component{
    render() {
        return(
            <div className="container-fluid mt-100 text-center d-flex">
                <div className="flex-row justify-content-center px-3 m-auto">
                    <div className="tarc mb-30 col-sm-10">
                        <h3>Свидетельство о постановке на учёт в налоговом органе</h3>
                    </div>
                    <div className="tarc-page col-sm-12">
                        <img className="tarc" src={tarc} style={{width:'100%'}} alt="tarc" />
                    </div>
                </div>
            </div>
        )
    }
}