import React from "react";
import csr from './img/csr/csr.jpg';

export class Csr extends React.Component{
    render() {
        return (
            <div className="container-fluid mt-100 text-center d-flex">
                <div className="flex-row justify-content-center px-3 m-auto">
                    <div className="csr mb-30 col-sm-10">
                        <h3>Свидетельство о государственной регистрации Фонда</h3>
                    </div>
                    <div className="csr-page col-sm-12">
                        <img className="csr" src={csr} style={{width:'100%'}} alt="csr" />
                    </div>
                </div>
            </div>
        )
    }
}