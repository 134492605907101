import React from "react";

export class Inheritance extends React.Component{
    render() {
        return(
            <section className="mt-100">
                <div className="container-fluid text-center d-flex">
                    <div className="flex-row justify-content-lg-start px-3 m-auto">
                        <div className="article mb-10 col-sm-12">
                            <p>
                                Уход любимого питомца – невосполнимая потеря и огромная трагедия, пережить боль
                                от которой не просто. Мы понимаем. Также как понимаем, что далеко не все сразу готовы
                                впустить в своё сердце нового хвостатого и подарить ему дом.
                            </p>
                            <p>
                                В таких случаях остаётся наследство, которое часто в растерянных чувствах не понятно куда
                                девать: корм, лекарства, миски, лежаки, поводки, ошейники, одежду, домики, игрушки, лотки,
                                наполнитель для кошачьего туалета и т.д. Всё это убрано с глаз, чтобы не рвать сердце, и
                                сиротливо дожидается своего часа быть отправленным на помойку.
                            </p>
                            <p>
                                Но ведь можно не выбрасывать, а передать тем, кто ещё не обрёл свой дом и очень нуждается в
                                помощи. Пожалуйста, свяжитесь с нами – благотворительный  фонд "Буду рядом" с
                                благодарностью примет в дар вещи ушедшего питомца и передаст нуждающимся.
                            </p>
                            <p>
                                Уверены, лучший способ почтить память того, кто был верным другом до последнего вздоха -
                                доброе дело.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}