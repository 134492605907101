import React from "react";
import mushka1 from './img/cats/mushka1.jpg';
import mushka2 from './img/cats/mushka2.jpg';
import mushka3 from './img/cats/mushka3.jpg';
import mushka4 from './img/cats/amushka5.png';
import CatsAgremment from './documents/agreement_cats.pdf';
import CatsQ from './documents/questionnaire_cats.docx';

export class Mushka extends React.Component{
    render() {
        return(
            <div className="container-fluid mt-100 mb-50 text-center d-flex">
                <div className="row justify-content-center m-auto">
                    <div className="mushka mb-30 col-sm-12">
                        <h3>Мушка</h3>
                    </div>
                    <div className="mushka mb-30 col-sm-3">
                        <img className="mushka1" src={mushka1} style={{width:'100%'}} alt="mushka1" />
                    </div>
                    <div className="mushka mb-30 col-sm-3">
                        <img className="mushka2" src={mushka2} style={{width:'100%'}} alt="mushka2" />
                    </div>
                    <div className="mushka mb-30 col-sm-3">
                        <img className="mushka3" src={mushka3} style={{width:'100%'}} alt="mushka3" />
                    </div>
                    <div className="mushka mb-30 col-sm-3">
                        <img className="mushka3" src={mushka4} style={{width:'100%'}} alt="mushka3" />
                    </div>
                    <div className="mushka mb-30 col-sm-12">
                        <h6>Возраст: 3 года.</h6>
                        <p>Есть такие кошки – невидимки. Они живут настолько ненавязчиво, что окружающие их совсем не замечают. Порой даже и не догадывается об их существовании. Не в правилах таких кошек докучать кому-либо, требовать внимания или какой-то помощи. Невидимки тихонечко ютятся на задворках жизни и как будто постоянно извиняются за своё присутствие в этом мире…</p>
                        <p>Мушка – та самая невидимка. Невидимка с тонюсеньким жалобным голосочком. Её “мяу” всегда неуверенное, тихое, робкое, почти незаметное как и она сама. Живя уже почти год на домашней передержке, она продолжает оставаться невидимой и всем своим поведением как будто говорит: «тут же есть и другие кошки, им нужнее. А я чего? Я ничего…»</p>
                        <p>Мушенька нежнейшее создание, буквально сражающее наповал своей покорностью. Но её суровый взгляд в камеру часто вводит в заблуждение, не давая увидеть хрупкость, скромность и робость, открывающиеся лишь при личном знакомстве.</p>
                        <p>А ведь Мушеньке так нужен человек, который навсегда освободит её от мантии-невидимки, накинутой словно заклятие злой колдуньи и спадающей лишь ночью, когда все отправляются в царство Морфея. Именно тогда Муша тихонечко приходит на кровать к человеку и сладко-сладко засыпает рядышком…</p>
                        <p>Мушенька здорова, привита, стерилизована, имеет ветпаспорт. Лоток и когтеточка освоены на 5+. Пристраивается по <a href={CatsAgremment} download="agreement_cats.pdf">договору ответственного содержания</a> с ненавязчивым отслеживанием дальнейшей судьбы.</p>
                    </div>
                    <div className="chivas mb-30 col-sm-12">
                        <h6><b>Если Вам понравилась Мушка, Вы можете заполнить <a href={CatsQ} download="questionnaire_cats.docx">Анкету потенциального хозяина</a> и после направить нам её на e-mail <a href="mailto:info@budu-ryadom.ru">info@budu-ryadom.ru</a> с указанием в теме письма её имени</b></h6>
                    </div>
                </div>
            </div>
        )
    }
}