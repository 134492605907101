import React from "react";
import bongo1 from "./img/dogs/bongo/bongo1.JPG";
import bongo2 from "./img/dogs/bongo/bongo2.JPG";
import bongo3 from "./img/dogs/bongo/bongo3.JPG";
import bongo4 from "./img/dogs/bongo/bongo4.JPG";
import DogsAgremment from "./documents/agreement_dogs.pdf";
import DogsQ from "./documents/questionnaire_dogs.docx";

export class Bongo extends React.Component{
    render() {
        return(
            <div className="container-fluid mt-100 mb-50 text-center d-flex">
                <div className="row justify-content-center m-auto">
                    <div className="bongo mb-30 col-sm-12">
                        <h3>Бонго</h3>
                    </div>
                    <div className="bongo mb-30 col-sm-3">
                        <img className="bongo" src={bongo1} style={{width:'100%'}} alt="bongo1" />
                    </div>
                    <div className="bongo mb-30 col-sm-3">
                        <img className="bongo" src={bongo2} style={{width:'100%'}} alt="bongo2" />
                    </div>
                    <div className="bongo mb-30 col-sm-3">
                        <img className="bongo" src={bongo3} style={{width:'100%'}} alt="bongo3" />
                    </div>
                    <div className="bongo mb-30 col-sm-3">
                        <img className="bongo" src={bongo4} style={{width:'100%'}} alt="bongo4" />
                    </div>
                    <div className="bongo mb-30 col-sm-12">
                        <h6>Возраст: 1 год.</h6>
                        <h6>Рост: 45 см.</h6>
                        <h6>Темперамент: холерик.</h6>
                        <h6>Психотип: экстраверт.</h6>
                        <p>
                            «Вся жизнь – игра» – девиз  Бонго. Он настоящий ценитель игрушек, а если таковых нет, легко превратит в игрушку палку, тряпку или любой другой предмет, до которого сможет дотянуться.
                        </p>
                        <p>
                            Кроме того, как все пытливые дети, Бонго с энтузиазмом изучает мир. А как лучше всего понять: с чем имеешь дело? Конечно же, попробовать на вкус! Для собаки зубы – всё равно, что для человека руки. Так и есть. Поэтому всё, что дорого сердцу или может оказаться опасным для здоровья малыша, лучше заранее убрать подальше.
                        </p>
                        <p>
                            Зато Бонго – прирождённая звезда поисковых игр и ноузворкинга. Тут ему равных нет, потому что Бонго не только любопытный, но и очень сообразительный. Вы и сами получите удовольствие от таких занятий. Ещё бы, ведь будете искренне гордиться неминуемо достигаемыми  успехами!
                        </p>
                        <p>
                            Вместе с тем, даже самую интересную игру или игрушку Бонго с лёгкостью променяет на внимание человека. Ох, как же он любит ласку! Почесушки, поглаживания, объятия… Всё это Бонго принимает с огромной благодарностью. А если дополнить контакт вкуснятиной, то его счастью вообще не будет предела.
                        </p>
                        <p>
                            Бонго - настоящий компаньон, готовый везде сопровождать своего хозяина. Если ведёте активный образ жизни, лучшего партнёра не найти. Особенно в автопутешествиях – Бонго идеальный дисциплинированный пассажир. Он будет с удовольствием открывать для себя новые места, встречать рассветы и провожать закаты, слушать треск костра и ночевать под бочком в палатке… и делиться с вами своей радостью.
                        </p>
                        <p>
                            Бонго здоров, привит, кастрирован, обработан от всех паразитов. Пристраивается по <a href={DogsAgremment} download="agreement_dogs.pdf">договору ответственного содержания</a> с ненавязчивым отслеживанием дальнейшей судьбы.
                        </p>
                    </div>
                    <div className="chivas mb-30 col-sm-12">
                        <h6><b>Если Вам понравился Бонго, Вы можете заполнить <a href={DogsQ} download="questionnaire_dogs.docx">Анкету потенциального хозяина</a> и после направить нам её на e-mail <a href="mailto:info@budu-ryadom.ru">info@budu-ryadom.ru</a> с указанием в теме письма его имени</b></h6>
                    </div>
                </div>
            </div>
        )
    }
}