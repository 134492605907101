import React from "react";
import hugo1 from './img/dogs/hugo/hugo1.jpg';
import hugo2 from './img/dogs/hugo/hugo2.jpg';
import hugo3 from './img/dogs/hugo/hugo3.jpg';
import hugo4 from './img/dogs/hugo/hugo4.jpg';
import DogsAgremment from "./documents/agreement_dogs.pdf";
import DogsQ from "./documents/questionnaire_dogs.docx";

export class Hugo extends React.Component{
    render() {
        return(
            <div className="container-fluid mt-100 mb-50 text-center d-flex">
                <div className="row justify-content-center m-auto">
                    <div className="hugo mb-30 col-sm-12">
                        <h3>Хьюго</h3>
                    </div>
                    <div className="hugo mb-30 col-sm-3">
                        <img className="hugo1" src={hugo1} style={{width:'100%'}} alt="hugo1" />
                    </div>
                    <div className="hugo mb-30 col-sm-3">
                        <img className="hugo2" src={hugo2} style={{width:'100%'}} alt="hugo2" />
                    </div>
                    <div className="hugo mb-30 col-sm-3">
                        <img className="hugo3" src={hugo3} style={{width:'100%'}} alt="hugo3" />
                    </div>
                    <div className="hugo mb-30 col-sm-3">
                        <img className="hugo4" src={hugo4} style={{width:'100%'}} alt="hugo4" />
                    </div>
                    <div className="hugo mb-30 col-sm-12">
                        <h6>Возраст: 5 лет.</h6>
                        <h6>Рост: 60 см.</h6>
                        <p>Фенотип хаски со всем набором соответствующих качеств.</p>
                        <p>Хьюго дружелюбно настроен к детям, но с незнакомыми людьми первые минуты предпочитает держать дистанцию, присматриваясь и привыкая. Зато потом с удовольствием идёт на контакт, подставляя бочка для почесушек. С человеком, которому доверяет, превращается в ласкового котика и настоящую липучку, постоянно просящую внимания.</p>
                        <p>Хьюго толерантен к другим домашним животным, неконфликтен и прекрасно ладит с сородичами, как истинный джентельмен всегда уступая девочкам. С ним Вам никогда не придётся краснеть на собачьей площадке за агрессивное поведение. Скорее наоборот, он быстро соберёт там собственную стаю и придумает всем весёлое развлечение.</p>
                        <p>Как и все ездовые породы, Хьюго очень энергичен – необходимы активные прогулки несколько часов в день для сброса постоянно накапливаемой энергии. Иначе он может направить её в деструктивное русло – стать дизайнером интерьера или креативным модельером одежды. Идеально, если Вы живёте в частном доме с большой закрытой территорией, по которой он сможет свободно перемещаться в течение дня.</p>
                        <p>Пока же творческая натура Хьюго находит свою реализацию в вокальных талантах. Мальчишка с энтузиазмом подпевает абсолютно любым музыкальным произведениям и ритмично пританцовывает в такт. Причем сначала несколько секунд прислушивается, выбирает тональность, подстраивается и вступает. Настоящий артист. А иногда поёт и а капелла. Необходимо это учитывать во избежание конфликтов с соседями.</p>
                        <p>Хьюго, как и все хаски, обладает независимым характером, что выражается не только в сложностях при дрессировке (как уж тут сосредоточиться на командах, когда энергия распирает), но и в склонности к побегам и бродяжничеству, поэтому на открытых территориях необходимо гулять с ним строго на поводке.</p>
                        <p>Из особенностей по здоровью, у Хьюго идиопатическая эпилепсия. Приступы успешно блокируются ежедневным приёмом фенобарбитала.</p>
                        <p>Хьюго привит и обработан от всех паразитов. Пристраивается по <a href={DogsAgremment} download="agreement_dogs.pdf">договору ответственного содержания</a> с ненавязчивым отслеживанием дальнейшей судьбы.</p>
                    </div>
                    <div className="chivas mb-30 col-sm-12">
                        <h6><b>Если Вам понравился Хьюго, Вы можете заполнить <a href={DogsQ} download="questionnaire_dogs.docx">Анкету потенциального хозяина</a> и после направить нам её на e-mail <a href="mailto:info@budu-ryadom.ru">info@budu-ryadom.ru</a> с указанием в теме письма его имени</b></h6>
                    </div>
                </div>
            </div>
        )
    }
}