import React from "react";
import rassel1 from "./img/dogs/rassel/rassel1.JPG";
import rassel2 from "./img/dogs/rassel/rassel2.JPG";
import rassel3 from "./img/dogs/rassel/rassel3.JPG";
import rassel4 from "./img/dogs/rassel/rassel4.JPG";
import DogsAgremment from "./documents/agreement_dogs.pdf";
import DogsQ from "./documents/questionnaire_dogs.docx";

export class Rassel extends React.Component{
    render() {
        return(
            <div className="container-fluid mt-100 mb-50 text-center d-flex">
                <div className="row justify-content-center m-auto">
                    <div className="rassel mb-30 col-sm-12">
                        <h3>Рассел</h3>
                    </div>
                    <div className="rassel mb-30 col-sm-3">
                        <img className="rassel" src={rassel1} style={{width:'100%'}} alt="rassel1" />
                    </div>
                    <div className="rassel mb-30 col-sm-3">
                        <img className="rassel" src={rassel2} style={{width:'100%'}} alt="rassel2" />
                    </div>
                    <div className="rassel mb-30 col-sm-3">
                        <img className="rassel" src={rassel3} style={{width:'100%'}} alt="rassel3" />
                    </div>
                    <div className="rassel mb-30 col-sm-3">
                        <img className="rassel" src={rassel4} style={{width:'100%'}} alt="rassel4" />
                    </div>
                    <div className="rassel mb-30 col-sm-12">
                        <h6>Возраст: 1 год.</h6>
                        <h6>Рост: 45 см.</h6>
                        <h6>Темперамент: холерик.</h6>
                        <h6>Психотип: экстраверт.</h6>
                        <p>
                            Мечтали о хаски, но жилищные условия не позволяют? Тогда Рассел ждёт именно вас! До размеров чистокровного хаски Рассел так и не дорос.
                        </p>
                        <p>
                            Зато все остальные качества данной породы унаследовал в полной мере: гипперактивный, мега энергичный, дружелюбный, совершенно не склонен к агрессии, отлично ладит с детками, но ввиду эмоциональности и порывистости стоит внимательно контролировать общение с малышами – аккуратность и деликатность не его «конёк», может повалить во время обнимашек или полезть целоваться в лицо.
                        </p>
                        <p>
                            К животным, с которыми живёт на одной территории, лоялен, но может погнаться за уличной кошкой или птицей, а также залезть в домашний курятник и навести там «порядок», прихватив с собой кого-то в качестве добычи.
                        </p>
                        <p>
                            Как и всем хаски, Расселу необходимы длительные активные прогулки и интеллектуальные нагрузки. В противном случае, в попытках сбросить накопившуюся энергию, начнёт развлекать себя сам: устроит концерт соседям с задушевными песнями о скуке по хозяину, разберёт шкафы и проверит на устойчивость их содержимое, что-то попробует на зуб, сделает ремонт в квартире… Ну а что вы хотели? Типичный хаски. Говорят, что хаски – шпицовые. А мы подозреваем, что в роду у этих собак были еноты)
                        </p>
                        <p>
                            Вместе с тем, благодаря своей харизме, общительности, лёгкому нраву и феноменальной сообразительности, Рассел точно станет душой любой компании, звездой района и бесконечным источником позитива для своей семьи и окружающих. А ещё верным и преданным другом.
                        </p>
                        <p>
                            Рассел здоров, привит, кастрирован, обработан от всех паразитов. Пристраивается по <a href={DogsAgremment} download="agreement_dogs.pdf">договору ответственного содержания</a> с ненавязчивым отслеживанием дальнейшей судьбы.
                        </p>
                    </div>
                    <div className="chivas mb-30 col-sm-12">
                        <h6><b>Если Вам понравился Рассел, Вы можете заполнить <a href={DogsQ} download="questionnaire_dogs.docx">Анкету потенциального хозяина</a> и после направить нам её на e-mail <a href="mailto:info@budu-ryadom.ru">info@budu-ryadom.ru</a> с указанием в теме письма его имени</b></h6>
                    </div>
                </div>
            </div>
        )
    }
}