import React from 'react';
import ugrle1 from './img/ugrle/ugrle_page_1.jpg';
import ugrle2 from './img/ugrle/ugrle_page_2.jpg';
import ugrle3 from './img/ugrle/ugrle_page_3.jpg';
import ugrle4 from './img/ugrle/ugrle_page_4.jpg';

export class Ugrle extends React.Component{
    render(){
        return(
            <div className="container-fluid mt-100 mb-50 text-center d-flex">
                <div className="flex-row justify-content-center px-3 m-auto">
                    <div className="ugrle mb-30 col-sm-10">
                        <h3>Свидетельство о государственной регистрации юридического лица</h3>
                    </div>
                    <div className="ugrle-pages col-sm-12">
                        <img className="ugrle1" src={ugrle1} style={{width:'100%'}} alt="page1" />
                        <img className="ugrle2" src={ugrle2} style={{width:'100%'}} alt="page2" />
                        <img className="ugrle3" src={ugrle3} style={{width:'100%'}} alt="page3" />
                        <img className="ugrle4" src={ugrle4} style={{width:'100%'}} alt="page4" />
                    </div>
                </div>
            </div>
        )
    }
}