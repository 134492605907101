import React from "react";
import villi1 from './img/cats/avilli.png';
import villi2 from './img/cats/avilli2.png';
import villi3 from './img/cats/avilli3.png';
import CatsAgremment from './documents/agreement_cats.pdf';
import CatsQ from './documents/questionnaire_cats.docx';

export class Villi extends React.Component{
    render() {
        return(
            <div className="container-fluid mt-100 mb-50 text-center d-flex">
                <div className="row justify-content-center m-auto">
                    <div className="villi mb-30 col-sm-12">
                        <h3>Вилли</h3>
                    </div>
                    <div className="villi mb-30 col-sm-4">
                        <img className="villi1" src={villi1} alt="villi1" />
                    </div>
                    <div className="villi mb-30 col-sm-4">
                        <img className="villi2" src={villi2} alt="villi2" />
                    </div>
                    <div className="villi mb-30 col-sm-4">
                        <img className="villi3" src={villi3} alt="villi3" />
                    </div>
                    <div className="villi mb-30 col-sm-12">
                        <h6>Возраст: 1,5 года.</h6>
                        <p>Ласковый контактный мальчик. Активный. Любит игры, в которые вовлекает всех и всё вокруг. Вопрос решается активными играми с кошачьей удочкой в течение 15-20 минут, интерактивными и интеллектуальными игрушками, а также уборкой из зоны доступа всего ценного и мелкого.</p>
                        <p>Вилли с благодарностью принимает внимание, поглаживания и почесушечки. Ладит с собаками.</p>
                        <p>Прекрасно владеет лоточком и когтеточкой. Здоров, привит, кастрирован, обработан от всех паразитов, имеет ветпаспорт.</p>
                        <p>Пристраивается строго единственным представителем кошачьих в доме или в паре с братиком Чивасом. Отдаётся по <a href={CatsAgremment} download="agreement_cats.pdf">договору ответственного содержания</a> с ненавязчивым отслеживанием дальнейшей судьбы.</p>
                    </div>
                    <div className="chivas mb-30 col-sm-12">
                        <h6><b>Если Вам понравился Вилли, Вы можете заполнить <a href={CatsQ} download="questionnaire_cats.docx">Анкету потенциального хозяина</a> и после направить нам её на e-mail <a href="mailto:info@budu-ryadom.ru">info@budu-ryadom.ru</a> с указанием в теме письма его имени</b></h6>
                    </div>
                </div>
            </div>
        )
    }
}