import React from "react";
import esmie1 from './img/dogs/esmie/esmie1.jpeg';
import esmie2 from './img/dogs/esmie/esmie2.jpeg';
import esmie3 from './img/dogs/esmie/esmie3.jpeg';
import esmie4 from './img/dogs/esmie/esmie4.jpeg';
import DogsAgremment from "./documents/agreement_dogs.pdf";
import DogsQ from "./documents/questionnaire_dogs.docx";

export class Esmie extends React.Component{
    render() {
        return(
            <div className="container-fluid mt-100 mb-50 text-center d-flex">
                <div className="row justify-content-center m-auto">
                    <div className="esmie mb-30 col-sm-12">
                        <h3>Эсми</h3>
                    </div>
                    <div className="esmie mb-30 col-sm-3">
                        <img className="esmie1" src={esmie1} style={{width:'100%'}} alt="esmie1" />
                    </div>
                    <div className="esmie mb-30 col-sm-3">
                        <img className="esmie2" src={esmie2} style={{width:'100%'}} alt="esmie2" />
                    </div>
                    <div className="esmie mb-30 col-sm-3">
                        <img className="esmie3" src={esmie3} style={{width:'100%'}} alt="esmie3" />
                    </div>
                    <div className="esmie mb-30 col-sm-3">
                        <img className="esmie4" src={esmie4} style={{width:'100%'}} alt="esmie4" />
                    </div>
                    <div className="esmie mb-30 col-sm-12">
                        <h6>Возраст: 3 года.</h6>
                        <h6>Рост: 40 см.</h6>
                        <p>Энеджайзер Эсми не даст грустить ни минуты!</p>
                        <p>Она синоним радости и смеха, которыми моментально наполнит Ваш дом. Рядом с ней просто невозможно предаваться унынью!</p>
                        <p>Эсми сражает наповал своим лёгким нравом и детской непосредственностью, которую, уверены, пронесёт через всю жизнь. В ней нет ни капли агрессии, и она совсем не умеет обижаться. А лучшее, что она умеет делать — это любить и обниматься!</p>
                        <p>Приучена к выгулу, ладит абсолютно со всеми, иногда невинно шалит, чем обогатит Ваш запас весёлых историй, которыми обязательно захочется делиться. Отлично вольётся в семью с детьми!</p>
                        <p>Подарите себе верного друга, а ей - целый домашний мир, полный любви и заботы.</p>
                        <p>Эсми абсолютно здорова, привита, стерилизована, обработана от всех паразитов, имеет ветпаспорт. Пристраивается по <a href={DogsAgremment} download="agreement_dogs.pdf">договору ответственного содержания</a> с ненавязчивым отслеживанием дальнейшей судьбы.</p>
                    </div>
                    <div className="chivas mb-30 col-sm-12">
                        <h6><b>Если Вам понравилась Эсми, Вы можете заполнить <a href={DogsQ} download="questionnaire_dogs.docx">Анкету потенциального хозяина</a> и после направить нам её на e-mail <a href="mailto:info@budu-ryadom.ru">info@budu-ryadom.ru</a> с указанием в теме письма её имени</b></h6>
                    </div>
                </div>
            </div>
        )
    }
}