import React from 'react';
import four_paws from './img/partners/4lapy.png';
import isadora from './img/partners/isadora.jpg';
import debby_dog from './img/partners/debby_dog.png';
import ozon from './img/partners/ozon.png';
import muzzle from './img/partners/muzzle.png';

export class Partners extends React.Component{
    render(){
        return(
            <div className="partners-container mt-100 mb-50 text-center  d-flex">
                <div className="row px-3 m-auto">
                    <div className="partners mb-30 col-sm-10">
                        <h1>Партнёры фонда</h1>
                    </div>
                    <div className="partner-item col-sm-3">
                        <a href="https://4lapy.ru/"><img className="four_paws" src={four_paws} style={{height:'200px'}} alt="4_lapy" /></a>
                        <h4>Зоотовары и услуги</h4>
                        <p>
                            Четыре Лапы — крупнейшая розничная зоокомпания в России, которая помогает владельцам домашних животных заботиться о своих питомцах на всех этапах жизни и предоставляет им все необходимые услуги по уходу: от продажи корма до ветеринарной помощи.
                            Четыре Лапы – это:
                            <ul>- Более 25 000 товаров.</ul>
                            <ul>- Более 470 магазинов.</ul>
                            <ul>- Бесплатная доставка.</ul>
                            <ul>- Удобное мобильное приложение.</ul>
                            <ul>- Ветеринарные Центры и Груминг.</ul>
                        </p>
                    </div>
                    <div className="partner-item col-sm-3">
                        <a href="https://www.isa-dora.com/"><img className="isadora" src={isadora} style={{height:'200px'}} alt="isadora" /></a>
                        <h4>Груминг-студия</h4>
                    </div>
                    <div className="partner-item col-sm-3">
                        <a href="https://vk.com/debbydog"><img className="debby_dog" src={debby_dog} style={{height:'200px'}} alt="debby_dog" /></a>
                        <h4>Адресники и шнурки для них, ошейники для животных и браслеты для людей</h4>
                    </div>
                    <div className="partner-item col-sm-3">
                        <a href="https://www.ozon.ru/seller/fond-budu-ryadom-365913/products/?miniapp=seller_365913"><img className="ozon" src={ozon} style={{height:'200px'}} alt="ozon" /></a>
                        <h4>Интернет-магазин</h4>
                        <p>
                            Хорошие дела могут стать привычкой вместе с Ozon. Теперь, совершая покупки, вы помогаете тем, кому это действительно нужно.
                        </p>
                    </div>
                    <div className="partner-item col-sm-3">
                        <a href="https://muzzle-pet.com/"><img className="muzzle" src={muzzle} style={{width:'270px'}} alt="muzzle" /></a>
                        <h4>Muzzle. Товары для животных и любящих хозяев</h4>
                        <p>
                            Миссия Muzzle — создать доступную и стильную pet-экосистему для комфортной жизни питомцев и их владельцев
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}