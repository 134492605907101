import React from "react";
import leya1 from './img/cats/leya/leya_1.JPG';
import leya2 from './img/cats/leya/leya_2.JPG';
import leya3 from './img/cats/leya/leya_3.JPG';
import leya4 from './img/cats/leya/leya_4.JPG';
import CatsAgremment from './documents/agreement_cats.pdf';
import CatsQ from './documents/questionnaire_cats.docx';


export class Leya extends React.Component{
    render() {
        return(
            <div className="container-fluid mt-100 mb-50 text-center d-flex">
                <div className="row justify-content-center m-auto">
                    <div className="leya mb-30 col-sm-12">
                        <h3>Лея</h3>
                    </div>
                    <div className="leya mb-30 col-sm-3">
                        <img className="leya" src={leya1} style={{width:'100%'}} alt="leya1" />
                    </div>
                    <div className="leya mb-30 col-sm-3">
                        <img className="leya2" src={leya2} style={{width:'100%'}} alt="leya2" />
                    </div>
                    <div className="leya mb-30 col-sm-3">
                        <img className="leya3" src={leya3} style={{width:'100%'}} alt="leya3" />
                    </div>
                    <div className="leya mb-30 col-sm-3">
                        <img className="leya4" src={leya4} style={{width:'100%'}} alt="leya4" />
                    </div>
                    <div className="leya mb-30 col-sm-12">
                        <h6>Возраст: 3 года.</h6>
                        <p>Нежная, кроткая, застенчивая кошечка с невероятной красоты пушистой шубкой цвета крем-брюле.</p>
                        <p>Лея – девочка, которой нужно время, чтобы полностью раскрыться и освоиться. В награду за деликатность и терпение вы получите самую преданную компаньонку.</p>
                        <p>Малышка лояльна к своим сородичам, но активные дети и собачки могут её испугать. А вот спокойному рассудительному подростку Лея вполне может стать верным соратником, спокойным и понимающим другом, который поможет скрасить непростое время взросления.</p>
                        <p>Также она станет прекрасной отдушиной для взрослого человека, способного аккуратно, постепенно, без напора, завоёвывать доверие этой трепетной девочки. Взамен Лея обернётся ласковым согревающим солнышком, наполняющим безграничной любовью, теплом, спокойствием, надеждой и балансом ваш внутренний мир.</p>
                        <p>Шалости и вандализм Лее чужды, также как и ночные/утренние тыгыдыки и, вообще, любая суета. Она приучена к туалету в лоточек, а для маникюра и педикюра с удовольствием использует предназначенную для этого когтеточку.</p>
                        <p>Лея здорова, привита, стерилизована, обработана от всех паразитов. Отдаётся по <a href={CatsAgremment} download="agreement_cats.pdf">договору ответственного содержания</a> с ненавязчивым отслеживанием дальнейшей судьбы.</p>
                    </div>
                    <div className="leya mb-30 col-sm-12">
                        <h6><b>Если Вам понравилась Лея, Вы можете заполнить <a href={CatsQ} download="questionnaire_cats.docx">Анкету потенциального хозяина</a> и после направить нам её на e-mail <a href="mailto:info@budu-ryadom.ru">info@budu-ryadom.ru</a> с указанием в теме письма "Кошечка Лея"</b></h6>
                    </div>
                </div>
            </div>
        )
    }
}