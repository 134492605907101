import React from "react";
import roxy1 from './img/dogs/roxy/roxy1.jpg';
import roxy2 from './img/dogs/roxy/roxy2.jpg';
import roxy3 from './img/dogs/roxy/roxy3.jpg';
import roxy4 from './img/dogs/roxy/roxy4.jpg';
import DogsAgremment from "./documents/agreement_dogs.pdf";
import DogsQ from "./documents/questionnaire_dogs.docx";

export class Roxy extends React.Component{
    render() {
        return(
            <div className="container-fluid mt-100 mb-50 text-center d-flex">
                <div className="row justify-content-center m-auto">
                    <div className="roxy mb-30 col-sm-12">
                        <h3>Рокси</h3>
                    </div>
                    <div className="roxy mb-30 col-sm-3">
                        <img className="roxy1" src={roxy1} style={{width:'100%'}} alt="roxy1" />
                    </div>
                    <div className="roxy mb-30 col-sm-3">
                        <img className="roxy2" src={roxy2} style={{width:'100%'}} alt="roxy2" />
                    </div>
                    <div className="roxy mb-30 col-sm-3">
                        <img className="roxy3" src={roxy3} style={{width:'100%'}} alt="roxy3" />
                    </div>
                    <div className="roxy mb-30 col-sm-3">
                        <img className="roxy4" src={roxy4} style={{width:'100%'}} alt="roxy4" />
                    </div>
                    <div className="roxy mb-30 col-sm-12">
                        <h6>Возраст: 3 года.</h6>
                        <h6>Рост: 40 см.</h6>
                        <p>Настоящая девочка - чуть что, падает в обморок и немножечко истерит, первые 3 секунды… А потом понимает, что лапы-хвост на месте, и можно даже получать удовольствие от общения с людьми ранее не знакомыми. Особенно если они угощают чем-нибудь вкусненьким.</p>
                        <p>На самом деле - Рокси действительно очень нежная и трогательная, она буквально замирает, когда её гладят и получает бесконечное удовольствие от прикосновений. В ней сочетается задор и шаловливость, вместе со скромность и трепетностью.</p>
                        <p>Рокси обожает людей и готова целовать каждого с ног до головы. Если не можете себе представить преданность и верность визуально, то вот Вам пожалуйста - это она!</p>
                        <p>Рокси чудесно впишется в любую семью и гармонично дополнит её. С этой девочкой будет интересно открывать новые горизонты, учить её новым командам. Данный процесс принесёт Вам огромное удовольствие, ведь Рокси схватывает всё на лету!</p>
                        <p>Рокси великолепно ладит с другими животными, прекрасно общается с детками и очень быстро находит контакт со взрослыми. В голове так и представляется картина: парк, семья, пикник и Рокси, суетится рядом и приносит мяч.</p>
                        <p>Рокси проживает на домашней передержке, поэтому прекрасно знакома с бытовыми правилами и безукоризненно их соблюдает.</p>
                        <p>Мы можем ещё много рассказать о её достоинствах, но лучше один раз увидеть, чем сто раз услышать. Уверены, при личной встречи Вы влюбитесь в неё окончательно и бесповоротно.</p>
                        <p>Рокси абсолютно здорова, привита, стерилизована, обработана от всех паразитов. Пристраивается по <a href={DogsAgremment} download="agreement_dogs.pdf">договору ответственного содержания</a> с ненавязчивым отслеживанием дальнейшей судьбы.</p>
                    </div>
                    <div className="chivas mb-30 col-sm-12">
                        <h6><b>Если Вам понравилась Рокси, Вы можете заполнить <a href={DogsQ} download="questionnaire_dogs.docx">Анкету потенциального хозяина</a> и после направить нам её на e-mail <a href="mailto:info@budu-ryadom.ru">info@budu-ryadom.ru</a> с указанием в теме письма её имени</b></h6>
                    </div>
                </div>
            </div>
        )
    }
}