import React from "react";
import air1 from './img/dogs/air/air1.jpeg';
import air2 from './img/dogs/air/air2.jpeg';
import air3 from './img/dogs/air/air3.jpeg';
import DogsAgremment from "./documents/agreement_dogs.pdf";
import DogsQ from "./documents/questionnaire_dogs.docx";

export class Air extends React.Component{
    render() {
        return(
            <div className="container-fluid mt-100 mb-50 text-center d-flex">
                <div className="row justify-content-center m-auto">
                    <div className="air mb-30 col-sm-12">
                        <h3>Эйр</h3>
                    </div>
                    <div className="air mb-30 col-sm-3">
                        <img className="air1" src={air1} style={{width:'100%'}} alt="air1" />
                    </div>
                    <div className="air mb-30 col-sm-3">
                        <img className="air2" src={air2} style={{width:'100%'}} alt="air2" />
                    </div>
                    <div className="air mb-30 col-sm-3">
                        <img className="air3" src={air3} style={{width:'100%'}} alt="air3" />
                    </div>
                    <div className="air mb-30 col-sm-12">
                        <h6>Возраст: 14+ лет.</h6>
                        <h6>Рост: 30 см.</h6>
                        <p>Собака-интроверт, поэтому не докучает своим обществом. Эйр целыми днями лежит в углу и дремлет, выходя лишь на прогулку и до миски. У него свой собственный мир и он не очень любит, когда навязчиво вторгаются в его пространство.</p>
                        <p>Эйр вряд ли будет ходить за Вами хвостом, радостно встречать с работы, тыкаться мокрым носом по утрам и восторженно вылизывать лицо при первых признаках пробуждения. Он не принесёт Вам тапки и палочку, не станет с удовольствием демонстрировать выученные команды, и уж точно не будет их с энтузиазмом учить.</p>
                        <p>Зато Эйр никогда не позволит себе дестрой – с ним Вас не будут ждать распотрошенный диван, съеденные любимые туфли, разодранная обшивка двери и дыра в стене. Он не будет выть на весь дом, оповещая соседей о том, как же сильно скучает по любимым хозяевам. Вы сможете спокойно уходить на работу, не переживая как там любимый псыночка без Вас целый день. Псыночке без Вас будет просто прекрасно.</p>
                        <p>И когда придёте домой после тяжелого трудового дня, он не обслюнявит дорогое пальто и не разорвёт капроновые колготки в порыве страстных объятий. Вы спокойно пристегнёте поводок и пойдёте проветривать голову, думая во время прогулки каждый о чём-то своём.</p>
                        <p>Из особенностей здоровья: у Эйра катаракта, начальная стадия. При ней зрение страдает незначительно, пёсик не видит только деталей. И она не требует сложного ухода – дважды в день необходимо закапывать в глазки капли Офтан-Катахром. Эйр привык к данной процедуре и даёт проводить её без проблем. В остальном он абсолютно здоров, привит, кастрирован, обработан от всех паразитов, имеет ветеринарный паспорт.</p>
                        <p>А ещё Эйр без проблем ладит с сородичами и вряд ли заинтересуется кошками.</p>
                        <p><b>Эйр больше не пристраивается - он находится на домашней передержке, и фондом принято решение оставить его в фостерной семье на доживание, чтобы не подвергать стрессу от переезда и адаптации к новым условиям. Но вы можете стать его финансовым куратором на любую комфортную сумму и помогать фонду в оплате содержания Эйра - просто указывайте в комментарии к вашему пожертвованию "Эйр", и данная сумма будет направлена исключительно на нужды этого сладкого лисёнка.</b></p>
                    </div>
                </div>
            </div>
        )
    }
}