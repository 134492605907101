import React from "react";
import phone from './img/phone_keyword.png';
import operators from './img/operators.svg';

export class ShortNumber extends React.Component{
    render() {
        return(
            <section className="mt-100">
                <div className="container-fluid text-center d-flex">
                    <div className="row align-items-center">
                        <div className="col-lg-9 home-about-left no-padding">
                            <h5><i>При оплате с помощью короткого номера</i></h5>
                            <p>Отправьте SMS-сообщение на номер <b style={{color:"black"}}>3434</b> с ключевым словом <b style={{color:"black"}}>«НОСИКИ»</b> и через пробел укажите цифрами сумму пожертвования в рублях.<br />
                                Например: <b style={{color:"black"}}>НОСИКИ 100</b><br />
                                В ответ вы получите SMS для подтверждения платежа. Подтверждение платежа может прийти с короткого номера, на который отправляли первоначальное SMS-сообщение, или с сервисного номера оператора связи.
                            </p>
                            <img className="operators" src={operators} style={{width:'70%'}} alt="operators" />
                        </div>
                        <div className="col-lg-3 home-about-right no-padding">
                            <img className="phone" src={phone} style={{width:'250px'}} alt="phone"/>
                        </div>
                        <div className="col-lg-9 home-about-left no-padding">
                            <p>
                                Услуга доступна для абонентов МТС, Билайн, Мегафон, Yota.
                            </p>
                            <p>
                                Допустимый размер платежа — от 1 до 15 000 рублей. Стоимость отправки SMS на номер 3434 – бесплатно. Комиссия с абонента - 0%.
                            </p>
                            <p>
                                <li><a href="https://static.mts.ru/uploadmsk/contents/1655/soglashenie_easy_pay.pdf">Договор оферты МТС</a></li>
                                <li><a href="https://www.ruru.ru/storage/offers/OfferNSK.pdf">Договор оферты Билайн, лимиты</a></li>
                                <li><a href="https://www.round.ru/individuals/mobile_payments/oferta03072020.pdf">Договор оферты Мегафон, банка Раунд</a></li>
                                <li><a href="https://www.yota.ru/downloads/forms/oferta_m_platezh.pdf">Договор оферты Yota</a></li>
                            </p>
                            <p>
                                Мобильные платежи осуществляются через платёжный сервис MIXPLAT. Совершая платёж, вы принимаете условия <a href="https://единаякасса.рф/wp-content/uploads/Pravila_perevodov_EDS_RNKO_EK.pdf">Оферты</a>
                            </p>
                            <p>
                                Информацию о порядке и периодичности оказания услуг и условиях возврата вы можете получить по телефону <a href="tel:+74957750600">+7 495 775 06 00</a> или почте <a href="mailto:support@mixplat.ru">support@mixplat.ru</a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}