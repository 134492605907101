import React from "react";
import jimmy1 from './img/dogs/jimmy/jimmy1.jpeg';
import jimmy2 from './img/dogs/jimmy/jimmy2.jpeg';
import jimmy3 from './img/dogs/jimmy/jimmy3.jpeg';
import jimmy4 from './img/dogs/jimmy/jimmy4.jpeg';
import DogsAgremment from "./documents/agreement_dogs.pdf";
import DogsQ from "./documents/questionnaire_dogs.docx";

export class Jimmy extends React.Component{
    render() {
        return(
            <div className="container-fluid mt-100 mb-50 text-center d-flex">
                <div className="row justify-content-center m-auto">
                    <div className="jimmy mb-30 col-sm-12">
                        <h3>Джимми</h3>
                    </div>
                    <div className="jimmy mb-30 col-sm-3">
                        <img className="jimmy1" src={jimmy1} style={{width:'100%'}} alt="jimmy1" />
                    </div>
                    <div className="jimmy mb-30 col-sm-3">
                        <img className="jimmy2" src={jimmy2} style={{width:'100%'}} alt="jimmy2" />
                    </div>
                    <div className="jimmy mb-30 col-sm-3">
                        <img className="jimmy3" src={jimmy3} style={{width:'100%'}} alt="jimmy3" />
                    </div>
                    <div className="jimmy mb-30 col-sm-3">
                        <img className="jimmy4" src={jimmy4} style={{width:'100%'}} alt="jimmy4" />
                    </div>
                    <div className="jimmy mb-30 col-sm-12">
                        <h6>Возраст: 6 лет.</h6>
                        <h6>Рост: 50 см.</h6>
                        <p>Джими - метис лабрадора. Не будем утверждать на сколько близкий, но бабушка явно была ещё той вертихвосткой, что оригинальным образом отразилось на экстерьере этого красавца. А по характеру, так чистый лабрадор - добрый, ласковый, энергичный.</p>
                        <p>Джими идеальный питомец для спортивной, активной семьи. Мы так и видим его на утренней пробежке с хозяином или хозяйкой, или бегущим рядом с велосипедом, а, может быть, подпрыгивающим за тарелкой фрисби... </p>
                        <p>Можно бесконечно описывать сколько замечательных активностей поддержит этот парень, но лучше приехать и пообщаться с ним лично. Удовольствие и позитивное настроение гарантированно!</p>
                        <p>Джими приучен к выгулу, любит кататься в машине и прекрасно ведёт себя дома. Для своей семьи станет хорошим охранником.</p>
                        <p>Джими абсолютно здоров, привит, кастрирован, обработан от всех паразитов, имеет ветпаспорт. Пристраивается по <a href={DogsAgremment} download="agreement_dogs.pdf">договору ответственного содержания</a> с ненавязчивым отслеживанием дальнейшей судьбы.</p>
                    </div>
                    <div className="chivas mb-30 col-sm-12">
                        <h6><b>Если Вам понравился Джими, Вы можете заполнить <a href={DogsQ} download="questionnaire_dogs.docx">Анкету потенциального хозяина</a> и после направить нам её на e-mail <a href="mailto:info@budu-ryadom.ru">info@budu-ryadom.ru</a> с указанием в теме письма его имени</b></h6>
                    </div>
                </div>
            </div>
        )
    }
}