import React from 'react';
import page1 from './img/charter/charter_page_1.jpg';
import page2 from './img/charter/charter_page_2.jpg';
import page3 from './img/charter/charter_page_3.jpg';
import page4 from './img/charter/charter_page_4.jpg';
import page5 from './img/charter/charter_page_5.jpg';
import page6 from './img/charter/charter_page_6.jpg';
import page7 from './img/charter/charter_page_7.jpg';
import page8 from './img/charter/charter_page_8.jpg';
import page9 from './img/charter/charter_page_9.jpg';
import page10 from './img/charter/charter_page_10.jpg';

export class Charter extends React.Component{
    render(){
        return(
            <div className="container-fluid mt-100 mb-50 text-center d-flex">
                <div className="flex-row justify-content-center px-3 m-auto">
                    <div className="charter mb-30 col-sm-10">
                        <h3>Устав фонда</h3>
                    </div>
                    <div className="charter-pages col-sm-12">
                        <img className="page1" src={page1} style={{width:'100%'}} alt="page1" />
                        <img className="page2" src={page2} style={{width:'100%'}} alt="page2" />
                        <img className="page3" src={page3} style={{width:'100%'}} alt="page3" />
                        <img className="page4" src={page4} style={{width:'100%'}} alt="page4" />
                        <img className="page5" src={page5} style={{width:'100%'}} alt="page5" />
                        <img className="page6" src={page6} style={{width:'100%'}} alt="page6" />
                        <img className="page7" src={page7} style={{width:'100%'}} alt="page7" />
                        <img className="page8" src={page8} style={{width:'100%'}} alt="page8" />
                        <img className="page9" src={page9} style={{width:'100%'}} alt="page9" />
                        <img className="page10" src={page10} style={{width:'100%'}} alt="page10" />
                    </div>
                </div>
            </div>
        )
    }
}