import React from "react";
import dambo1 from './img/dogs/dambo/dambo1.jpg';
import dambo2 from './img/dogs/dambo/dambo2.jpg';
import dambo3 from './img/dogs/dambo/dambo3.jpg';
import dambo4 from './img/dogs/dambo/dambo4.jpg';
import DogsAgremment from "./documents/agreement_dogs.pdf";
import DogsQ from "./documents/questionnaire_dogs.docx";

export class Dambo extends React.Component{
    render() {
        return(
            <div className="container-fluid mt-100 mb-50 text-center d-flex">
                <div className="row justify-content-center m-auto">
                    <div className="dambo mb-30 col-sm-12">
                        <h3>Дамбо</h3>
                    </div>
                    <div className="dambo mb-30 col-sm-3">
                        <img className="dambo1" src={dambo1} style={{width:'100%'}} alt="dambo1" />
                    </div>
                    <div className="dambo mb-30 col-sm-3">
                        <img className="dambo2" src={dambo2} style={{width:'100%'}} alt="dambo2" />
                    </div>
                    <div className="dambo mb-30 col-sm-3">
                        <img className="dambo3" src={dambo3} style={{width:'100%'}} alt="dambo3" />
                    </div>
                    <div className="dambo mb-30 col-sm-3">
                        <img className="dambo4" src={dambo4} style={{width:'100%'}} alt="dambo4" />
                    </div>
                    <div className="dambo mb-30 col-sm-12">
                        <h6>Возраст: 2 года.</h6>
                        <h6>Рост: 40 см.</h6>
                        <p>Этот малыш мог бы стать героем добрых детских сказок, не только благодаря необычной внешности, но и удивительному характеру. Он настоящее вдохновение для писателя или блогера.</p>
                        <p>Эталонный образец собаки-компаньона. Дамбо – сама любовь, нежность, трогательная привязанность. Он тот самый верный и преданный друг.</p>
                        <p>Очень ласковый и при этом тактичный мальчишка. Может бесконечно тихо стоять, прижавшись к человеку или уткнувшись в него носом. Для Дамбо важно просто быть рядом. Он собака-душа и собака для души.</p>
                        <p>Дамбо из тех, с кем здорово читать книжки или смотреть сериалы, теребя пальцами смешные уши-пропейлеры. С ним невероятно уютно. Он и есть уют.</p>
                        <p>Дамбо – собака-связь. Связь между сердцами, человеческим и собачьим. Спокойный и доверчивый пёсик, для которого хозяин станет центром вселенной. И Дамбо будет трепетно хранить эти отношения как самое дорогое, что есть в его жизни. В нём столько нерастраченной любви, и он будет бесконечно благодарен тому, кто её примет.</p>
                        <p>У Дамбо несахарный диабет, который требует пожизненного приёма гормонального препарата «Вазомирин» - необходимо дважды в день брызгать спреем в его кожанный нос. В остальном всё как у всех.</p>
                        <p>Дамбо привит, кастрирован, обработан от всех паразитов, имеет ветпаспорт. Пристраивается по <a href={DogsAgremment} download="agreement_dogs.pdf">договору ответственного содержания</a> с ненавязчивым отслеживанием дальнейшей судьбы.</p>
                    </div>
                    <div className="chivas mb-30 col-sm-12">
                        <h6><b>Если Вам понравился Дамбо, Вы можете заполнить <a href={DogsQ} download="questionnaire_dogs.docx">Анкету потенциального хозяина</a> и после направить нам её на e-mail <a href="mailto:info@budu-ryadom.ru">info@budu-ryadom.ru</a> с указанием в теме письма его имени</b></h6>
                    </div>
                </div>
            </div>
        )
    }
}