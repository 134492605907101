import React from "react";
import bu1 from "./img/dogs/bu/bu1.jpg";
import bu2 from "./img/dogs/bu/bu2.jpg";
import bu3 from "./img/dogs/bu/bu3.jpg";
import bu4 from "./img/dogs/bu/bu4.jpg";
import DogsAgremment from "./documents/agreement_dogs.pdf";
import DogsQ from "./documents/questionnaire_dogs.docx";

export class Bu extends React.Component{
    render() {
        return(
            <div className="container-fluid mt-100 mb-50 text-center d-flex">
                <div className="row justify-content-center m-auto">
                    <div className="bu mb-30 col-sm-12">
                        <h3>Бу</h3>
                    </div>
                    <div className="bu mb-30 col-sm-3">
                        <img className="bu" src={bu1} style={{width:'100%'}} alt="bu1" />
                    </div>
                    <div className="bu mb-30 col-sm-3">
                        <img className="bu" src={bu2} style={{width:'100%'}} alt="bu2" />
                    </div>
                    <div className="bu mb-30 col-sm-3">
                        <img className="bu" src={bu3} style={{width:'100%'}} alt="bu3" />
                    </div>
                    <div className="bu mb-30 col-sm-3">
                        <img className="bu" src={bu4} style={{width:'100%'}} alt="bu4" />
                    </div>
                    <div className="bu mb-30 col-sm-12">
                        <h6>Возраст: 3 года.</h6>
                        <h6>Рост: 50 см.</h6>
                        <h6>Темперамент: холерик.</h6>
                        <h6>Психотип: экстраверт.</h6>
                        <p>
                            Пристраивается в самые добрые руки настоящий сказочный эльф по имени Бу.
                        </p>
                        <p>
                            Изящный словно статуэтка Бу - воистину фантастическое создание. Как мифический персонаж
                            красочных саг он, робея перед незнакомыми людьми, торопится скорее укрыться от посторонних
                            глаз. Если не получается, старается казаться грозным и опасным, но непременно отступая. Вместе
                            с тем, позволяет себе только ворчание или звонкий лай, ни в коем случае никаких “кусь”. Бу не
                            способен навредить человеку. А стоит до него дотронуться - замирает и почти не дышит.
                        </p>
                        <p>
                            На беду Бу, его сказочная красота притягивает окружающих будто мощнейший магнит. Просто
                            комплимента часто недостаточно, каждый так и норовит к нему прикоснуться, ещё больше пугая
                            своим вниманием...
                        </p>
                        <p>
                            Всё решает волшебство. Точнее, вкусняшка в руках незнакомца. Это и есть тот самый ключик к
                            сердцу робкого эльфа Бу. Страх моментально сменяется любопытством, мордочка
                            заинтересованно поворачивается то влево, то вправо, а кожаный нос начинает жить своей
                            жизнью, выдавая па в ритме тамтамов. И вот уже Бу послушно выполняет все знакомые ему
                            команды: "ко мне", "сидеть", "лежать", "дай лапу", "дай пять", "покрутись", "зайка".
                        </p>
                        <p>
                            От человека, которому доверяет, готов не отлипать 24/7, ходя хвостиком и постоянно напоминая о
                            своей любви поцелуями и обнимашками. "Ты уже проснулся? Дайка я тебя расцелую! Ещё спишь?
                            Тогда свернусь колачиком у тебя под бочком, и будем дальше сладко спать вместе. А что это ты
                            там в телефоне смотришь? Время для почесушек! Держи скорее моё пузо!!!".
                        </p>
                        <p>
                            Бу прекрасно ладит с сородичами, видимо, принимая их за таких же эльфов. И вообще с
                            восторгом встречает всех шерстяных и крылатых обитателей каменных джунглей, стремясь
                            поиграть с каждым из них. И сколько не убеждай этого проказника, что с котиками шутки плохи, то
                            и дело ему приходится удирать от ненастроенного на дружеские догонялки кота.
                        </p>
                        <p>
                            Бу великолепно ездит в машине и в лифте, отлично ведёт себя в квартире, предпочитая грызть
                            игрушки, а не хозяйское имущество, и гордо несёт свои "грязные" делишки на улицу.
                        </p>
                        <p>
                            Бу здоров, привит, кастрирован, обработан от всех паразитов. Есть ветпаспорт. Пристраивается по <a href={DogsAgremment} download="agreement_dogs.pdf">договору ответственного содержания</a> с ненавязчивым отслеживанием дальнейшей судьбы.
                        </p>
                    </div>
                    <div className="chivas mb-30 col-sm-12">
                        <h6><b>Если Вам понравился Бу, Вы можете заполнить <a href={DogsQ} download="questionnaire_dogs.docx">Анкету потенциального хозяина</a> и после направить нам её на e-mail <a href="mailto:info@budu-ryadom.ru">info@budu-ryadom.ru</a> с указанием в теме письма его имени</b></h6>
                    </div>
                </div>
            </div>
        )
    }
}