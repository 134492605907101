import React from "react";

export class MainLow extends React.Component{
    render() {
        return (
            <section className="mt-100">
                <div className="container-fluid text-center d-flex">
                    <div className="flex-row justify-content-lg-start px-3 m-auto">
                        <div className="article mb-10 col-sm-12">
                            <h5><b>Согласно п.5 Статьи 3 Главы 1:</b></h5>
                            <p>
                                Жестокое обращение с животным – обращение с животным, которое привело или может привести к гибели,
                                увечью или иному повреждению здоровья животного (включая истязание животного, в том числе голодом,
                                жаждой, побоями, иными действиями), нарушение требований к содержанию животных, установленных
                                настоящим Федеральным законом, другими федеральными законами и иными нормативными правовыми
                                актами Российской Федерации (в том числе отказ владельца от содержания животного), причинившее вред
                                здоровью животного, либо неоказание при наличии возможности владельцем помощи животному,
                                находящемуся в опасном для жизни или здоровья состоянии;
                            </p>
                            <h5><b>Согласно п.6 Статьи 3 Главы 1:</b></h5>
                            <p>
                                Животное без владельца - животное, которое не имеет владельца или владелец которого неизвестен;
                            </p>
                            <h5><b>Согласно Статье 4 Главы 1 Основные принципы обращения с животными:</b></h5>
                            <p>
                                Обращение с животными основывается на следующих нравственных принципах и принципах гуманности:
                                <ul>
                                    1) отношение к животным как к существам, способным испытывать эмоции и физические страдания;
                                </ul>
                                <ul>
                                    2) ответственность человека за судьбу животного;
                                </ul>
                                <ul>
                                    3) воспитание у населения нравственного и гуманного отношения к животным;
                                </ul>
                                <ul>
                                    4) научно обоснованное сочетание нравственных, экономических и социальных интересов человека,
                                    общества и государства.
                                </ul>
                            </p>
                            <h5><b>Согласно Статье 9 Главы 3:</b></h5>
                            <p>
                                1. К общим требованиям к содержанию животных их владельцами относятся:
                                <ul>
                                    1) обеспечение надлежащего ухода за животными;
                                </ul>
                                <ul>
                                    2) обеспечение своевременного оказания животным ветеринарной помощи и своевременного
                                    осуществления обязательных профилактических ветеринарных мероприятий в соответствии с требованиями
                                    настоящего Федерального закона, других федеральных законов и иных нормативных правовых актов
                                    Российской Федерации, регулирующих отношения в области ветеринарии;
                                </ul>
                                <ul>
                                    3) принятие мер по предотвращению появления нежелательного потомства у животных;
                                </ul>
                                <ul>
                                    4) предоставление животных по месту их содержания по требованию должностных лиц органов
                                    государственного надзора в области обращения с животными при проведении ими проверок;
                                </ul>
                                <ul>
                                    5) осуществление обращения с биологическими отходами в соответствии с законодательством Российской
                                    Федерации.
                                </ul>
                                2. В случае отказа от права собственности на животное или невозможности его дальнейшего содержания
                                владелец животного обязан передать его новому владельцу или в приют для животных, которые могут
                                обеспечить условия содержания такого животного.
                            </p>
                            <h5><b>Согласно Статье 11 Главы 3 Защита животных от жестокого обращения:</b></h5>
                            <p>
                                <ul>
                                    1. Животные должны быть защищены от жестокого обращения.
                                </ul>
                                <ul>
                                    2. При обращении с животными не допускаются:
                                </ul>
                                <ul>
                                    1) проведение на животных без применения обезболивающих лекарственных препаратов для ветеринарного
                                    применения ветеринарных и иных процедур, которые могут вызвать у животных непереносимую боль;
                                </ul>
                                <ul>
                                    2) натравливание животных (за исключением служебных животных) на других животных;
                                </ul>
                                <ul>
                                    3) отказ владельцев животных от исполнения ими обязанностей по содержанию животных до их определения
                                    в приюты для животных или отчуждения иным законным способом;
                                </ul>
                                <ul>
                                    4) торговля животными в местах, специально не отведенных для этого;
                                </ul>
                                <ul>
                                    5) организация и проведение боев животных;
                                </ul>
                                <ul>
                                    6) организация и проведение зрелищных мероприятий, влекущих за собой нанесение травм и увечий
                                    животным, умерщвление животных;
                                </ul>
                                <ul>
                                    7) кормление хищных животных другими живыми животными в местах, открытых для свободного посещения,
                                    за исключением случаев, предусмотренных требованиями к использованию животных в культурно-
                                    зрелищных целях и их содержанию, установленными Правительством Российской Федерации.
                                </ul>
                            </p>
                            <h5><b>Согласно Статье 12 Главы 3 Запрещение пропаганды жестокого обращения с животными:</b></h5>
                            <p>
                                <ul>
                                    1. Запрещается пропаганда жестокого обращения с животными, а также призывы к жестокому обращению с
                                    животными.
                                </ul>
                                <ul>
                                    2. Запрещаются производство, изготовление, показ и распространение пропагандирующих жестокое
                                    обращение с животными кино-, видео- и фотоматериалов, печатной продукции, аудиовизуальной продукции,
                                    размещение таких материалов и продукции в информационно-телекоммуникационных сетях (в том числе в
                                    сети "Интернет") и осуществление иных действий, пропагандирующих жестокое обращение с животными.
                                </ul>
                            </p>
                            <h5><b>Согласно Статье 13 Главы 3 Требования к содержанию домашних животных:</b></h5>
                            <p>
                                <ul>
                                    1. При содержании домашних животных их владельцам необходимо соблюдать общие требования к
                                    содержанию животных, а также права и законные интересы лиц, проживающих в многоквартирном доме, в
                                    помещениях которого содержатся домашние животные.
                                </ul>
                                <ul>
                                    2. Не допускается использование домашних животных в предпринимательской деятельности, за
                                    исключением случаев, установленных Правительством Российской Федерации.
                                </ul>
                                <ul>
                                    3. Предельное количество домашних животных в местах содержания животных определяется исходя из
                                    возможности владельца обеспечивать животным условия, соответствующие ветеринарным нормам и
                                    правилам, а также с учетом соблюдения санитарно-эпидемиологических правил и нормативов.
                                </ul>
                                <ul>
                                    4. Выгул домашних животных должен осуществляться при условии обязательного обеспечения безопасности
                                    граждан, животных, сохранности имущества физических лиц и юридических лиц.
                                </ul>
                                <ul>
                                    5. При выгуле домашнего животного необходимо соблюдать следующие требования:
                                </ul>
                                <ul>
                                    1) исключать возможность свободного, неконтролируемого передвижения животного при пересечении
                                    проезжей части автомобильной дороги, в лифтах и помещениях общего пользования многоквартирных
                                    домов, во дворах таких домов, на детских и спортивных площадках;
                                </ul>
                                <ul>
                                    2) обеспечивать уборку продуктов жизнедеятельности животного в местах и на территориях общего
                                    пользования;
                                </ul>
                                <ul>
                                    3) не допускать выгул животного вне мест, разрешенных решением органа местного самоуправления для
                                    выгула животных.
                                </ul>
                                <ul>
                                    6. Выгул потенциально опасной собаки без намордника и поводка независимо от места выгула запрещается,
                                    за исключением случаев, если потенциально опасная собака находится на огороженной территории,
                                    принадлежащей владельцу потенциально опасной собаки на праве собственности или ином законном
                                    основании. О наличии этой собаки должна быть сделана предупреждающая надпись при входе на данную
                                    территорию.
                                </ul>
                                </p>
                                <h5><b>Согласно Статье 18 Главы 4 Организация мероприятий при осуществлении деятельности по обращению с
                                животными без владельцев:</b></h5>
                                <p>
                                    <ul>
                                        1. Мероприятия при осуществлении деятельности по обращению с животными без владельцев включают в
                                        себя:
                                    </ul>
                                    <ul>
                                        1) отлов животных без владельцев, в том числе их транспортировку и немедленную передачу в приюты для
                                        животных;
                                    </ul>
                                    <ul>
                                        2) содержание животных без владельцев в приютах для животных в соответствии с частью 7 статьи 16
                                        настоящего Федерального закона;
                                    </ul>
                                    <ul>
                                        3) возврат потерявшихся животных их владельцам, а также поиск новых владельцев поступившим в приюты
                                        для животных животным без владельцев;
                                    </ul>
                                    <ul>
                                        4) возврат животных без владельцев, не проявляющих немотивированной агрессивности, на прежние места
                                        их обитания после проведения мероприятий, указанных в пункте 2 настоящей части;
                                    </ul>
                                    <ul>
                                        5) размещение в приютах для животных и содержание в них животных без владельцев, которые не могут
                                        быть возвращены на прежние места их обитания, до момента передачи таких животных новым владельцам
                                        или наступления естественной смерти таких животных.
                                    </ul>
                                    <ul>
                                        2. При отлове животных без владельцев должны соблюдаться следующие требования:
                                    </ul>
                                    <ul>
                                        1) стерилизованные животные без владельцев, имеющие неснимаемые или несмываемые метки, отлову не
                                        подлежат, за исключением животных без владельцев, проявляющих немотивированную агрессивность в
                                        отношении других животных или человека;
                                    </ul>
                                    <ul>
                                        2) животные, имеющие на ошейниках или иных предметах сведения об их владельцах, передаются
                                        владельцам;
                                    </ul>
                                    <ul>
                                        3) применять вещества, лекарственные средства, способы, технические приспособления, приводящие к
                                        увечьям, травмам или гибели животных, не допускается;
                                    </ul>
                                    <ul>
                                        4) индивидуальные предприниматели и юридические лица, осуществляющие отлов животных без
                                        владельцев, несут ответственность за их жизнь и здоровье;
                                    </ul>
                                    <ul>
                                        5) индивидуальные предприниматели и юридические лица, осуществляющие отлов животных без
                                        владельцев, обязаны вести видеозапись процесса отлова животных без владельцев и бесплатно представлять
                                        по требованию уполномоченного органа исполнительной власти субъекта Российской Федерации копии этой
                                        видеозаписи;
                                    </ul>
                                    <ul>
                                        6) индивидуальные предприниматели и юридические лица, осуществляющие отлов животных без
                                        владельцев, обязаны представлять сведения об объеме выполненных работ в уполномоченный орган
                                        исполнительной власти субъекта Российской Федерации.
                                    </ul>
                                    <ul>
                                        3. Осуществлять отлов животных без владельцев в присутствии детей не допускается, за исключением
                                        случаев, если животные без владельцев представляют общественную опасность.
                                    </ul>
                                    <ul>
                                        4. Физические лица и юридические лица обязаны сообщать о нахождении животных без владельцев, не
                                        имеющих неснимаемых и несмываемых меток, на территориях или объектах, находящихся в собственности
                                        или пользовании таких лиц, в орган государственной власти субъекта Российской Федерации,
                                        уполномоченный осуществлять организацию мероприятий при осуществлении деятельности по обращению с
                                        животными без владельцев, и обеспечивать доступ на указанные территории или объекты представителям
                                        организации, осуществляющей отлов животных без владельцев.
                                    </ul>
                                    <ul>
                                        5. Содержание отловленных животных без владельцев в местах и пунктах временного содержания животных,
                                        не являющихся приютами для животных, не допускается.
                                    </ul>
                                    <ul>
                                        6. При возврате животных без владельцев на прежние места их обитания индивидуальные предприниматели
                                        и юридические лица, осуществляющие возврат животных без владельцев, обязаны вести видеозапись
                                        процесса возврата животных без владельцев и бесплатно представлять по требованию уполномоченного
                                        органа исполнительной власти субъекта Российской Федерации копии этой видеозаписи.
                                    </ul>
                                    <ul>
                                        7. Порядок осуществления деятельности по обращению с животными без владельцев устанавливается
                                        уполномоченным органом государственной власти субъекта Российской Федерации в соответствии с
                                        методическими указаниями, утвержденными Правительством Российской Федерации.
                                    </ul>
                                </p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}