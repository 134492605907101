import React from "react";
import milan1 from './img/dogs/milan/milan1.jpg';
import milan2 from './img/dogs/milan/milan2.jpg';
import milan3 from './img/dogs/milan/milan3.jpg';
import milan4 from './img/dogs/milan/milan4.jpg';
import DogsAgremment from "./documents/agreement_dogs.pdf";
import DogsQ from "./documents/questionnaire_dogs.docx";

export class Milan extends React.Component{
    render() {
        return(
            <div className="container-fluid mt-100 mb-50 text-center d-flex">
                <div className="row justify-content-center m-auto">
                    <div className="milan mb-30 col-sm-12">
                        <h3>Милан</h3>
                    </div>
                    <div className="milan mb-30 col-sm-3">
                        <img className="milan1" src={milan1} style={{width:'100%'}} alt="milan1" />
                    </div>
                    <div className="milan mb-30 col-sm-3">
                        <img className="milan2" src={milan2} style={{width:'100%'}} alt="milan2" />
                    </div>
                    <div className="milan mb-30 col-sm-3">
                        <img className="milan3" src={milan3} style={{width:'100%'}} alt="milan3" />
                    </div>
                    <div className="milan mb-30 col-sm-3">
                        <img className="milan4" src={milan4} style={{width:'100%'}} alt="milan4" />
                    </div>
                    <div className="milan mb-30 col-sm-12">
                        <h6>Возраст: 1 год.</h6>
                        <h6>Рост: 40 см.</h6>
                        <p>Милан - столица моды. А ещё так зовут очаровательного стильного мальчишку на фото – обладателя итальянского темперамента, мечтающего обрести собственный диван и любящую семью.</p>
                        <p>Милан не только невероятно хорош собой, но и может похвалиться прекрасным характером – он сама нежность, доброта, непринуждённость и коммуникабельность. С удовольствие купается во внимании и принимает ласку. Его сердце открыто всему миру!</p>
                        <p>Он настоящая звезда – Милан постоянно выступает представителем фонда на различных мероприятиях, где приводил окружающих в восторг своим природным обаянием и феноменальной сообразительностью. Но звёздная болезнь Милану чужда, он искренне рад каждому, для каждого найдёт время, подставит бочка для почесушек и с любовью лизнёт в нос.</p>
                        <p>И как у любой звезды, у Милана есть слабость – вкусняшки. За них он готов исполнить любое желание – Вы получите огромное удовольствие от совместных занятий, изучая новые команды. Стоит ли говорить, что на собачей площадке вы с ним тоже будете в центре внимания?</p>
                        <p>Милан великолепно дополнит семью с детьми или жизнь активных хозяев, с которыми с удовольствием будет открывать для себя простор и разнообразие этого мира. Он, вообще, очень компанейский парень: хоть в поход, хоть на море, хоть на дачу - ему везде будет весело.</p>
                        <p>В виду своей гиперконтактности, Милан может проявлять интерес к котикам, но исключительно с целью навязать свою компанию, без агрессии. Если котик не против, станут лучшими друзьями.</p>
                        <p>Кроме того, Милан прекрасно ладит с сородичами – может  быть не единственной собакой в доме.</p>
                        <p>Милан абсолютно здоров, привит, кастрирован, обработан от всех паразитов. Пристраивается по <a href={DogsAgremment} download="agreement_dogs.pdf">договору ответственного содержания</a> с ненавязчивым отслеживанием дальнейшей судьбы.</p>
                    </div>
                    <div className="chivas mb-30 col-sm-12">
                        <h6><b>Если Вам понравился Милан, Вы можете заполнить <a href={DogsQ} download="questionnaire_dogs.docx">Анкету потенциального хозяина</a> и после направить нам её на e-mail <a href="mailto:info@budu-ryadom.ru">info@budu-ryadom.ru</a> с указанием в теме письма его имени</b></h6>
                    </div>
                </div>
            </div>
        )
    }
}