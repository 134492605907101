import React from 'react';
import villi from './img/cats/villi.png';
import mushka from './img/cats/mushka.jpg';
import leya from './img/cats/leya.JPG';
import svane from './img/cats/svane.JPG';
import {Link} from "react-router-dom";

export class Cats extends React.Component{
    render() {
        return(
            <div className="container-fluid mt-100 mb-50 text-center d-flex">
                <div className="row justify-content-center m-auto">
                    <div className="cats mb-30 col-sm-12" >
                        <h3>Выбрать друга</h3>
                    </div>
                    <div className="col-sm-4">
                        <Link to="/villi">
                            <img className="villi" src={villi} style={{width:'100%'}} alt="villi"/>
                            <h4 className="text-center" style={{position:'relative'}} >Вилли</h4>
                        </Link>
                    </div>
                    <div className="col-sm-4">
                        <Link to="/leya">
                            <img className="leya" src={leya} style={{width:'100%'}} alt="leya"/>
                            <h4 className="text-center">Лея</h4>
                        </Link>
                    </div>
                    <div className="col-sm-4">
                        <Link to="/mushka">
                            <img className="mushka" src={mushka} style={{width:'100%'}} alt="mushka"/>
                            <h4 className="text-center">Мушка</h4>
                        </Link>
                    </div>
                    <div className="col-sm-4">
                        <Link to="/svane">
                            <img className="svane" src={svane} style={{width:'100%'}} alt="svane"/>
                            <h4 className="text-center">Свейн</h4>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}