import React from "react";
import fibi1 from './img/dogs/fibi/fibi1.jpeg';
import fibi2 from './img/dogs/fibi/fibi2.jpeg';
import fibi3 from './img/dogs/fibi/fibi3.jpeg';
import fibi4 from './img/dogs/fibi/fibi4.jpeg';
import DogsAgremment from "./documents/agreement_dogs.pdf";
import DogsQ from "./documents/questionnaire_dogs.docx";

export class Fibi extends React.Component{
    render() {
        return(
            <div className="container-fluid mt-100 mb-50 text-center d-flex">
                <div className="row justify-content-center m-auto">
                    <div className="fibi mb-30 col-sm-12">
                        <h3>Фиби</h3>
                    </div>
                    <div className="fibi mb-30 col-sm-3">
                        <img className="fibi1" src={fibi1} style={{width:'100%'}} alt="fibi1" />
                    </div>
                    <div className="fibi mb-30 col-sm-3">
                        <img className="fibi2" src={fibi2} style={{width:'100%'}} alt="fibi2" />
                    </div>
                    <div className="fibi mb-30 col-sm-3">
                        <img className="fibi3" src={fibi3} style={{width:'100%'}} alt="fibi3" />
                    </div>
                    <div className="fibi mb-30 col-sm-3">
                        <img className="fibi4" src={fibi4} style={{width:'100%'}} alt="fibi4" />
                    </div>
                    <div className="fibi mb-30 col-sm-12">
                        <h6>Возраст: 3,5 года.</h6>
                        <h6>Рост: 40 см.</h6>
                        <p><i>«Где-то во Вселенной... есть такой город, в котором всегда светит солнце! Там - всегда улыбаются! И царит счастье и радость, и океаны добра! С нашим миром, он соединён тоненькой невидимой нитью, звонкими голосами, мокрыми носами и виляющими хвостиками!»</i></p>
                        <p>Одна из представительниц того мира – прекрасная солнечная девочка Фиби. 3,5 года она в нашем мире ждёт. Ждёт СВОИХ человеков, которым так не хватает солнца!</p>
                        <p>Она чудесная (хоть так можно сказать и про любого представителя собачьей династии), но есть в ней что-то особенное. Неуловимо нежное, яркое, непосредственное, детское, доброе, настоящее и искреннее.</p>
                        <p>Только она умеет так мастерски улыбаться и смешно «говорить» от радости «аффф», виртуозно подставлять пузико для почесух и предано заглядывать не то что в глаза, а прямо в сердце.</p>
                        <p>Она выучила все правила вежливости этого мира и готова к абсолютно комфортному для людей проживанию.</p>
                        <p>Фиби была создана Вселенной для любви и в нашем мире она, чтобы разделить её с человеком.</p>
                        <p>Фиби здорова, привита, стерилизована, обработана от всех паразитов, имеет ветпаспорт. Пристраивается по <a href={DogsAgremment} download="agreement_dogs.pdf">договору ответственного содержания</a> с ненавязчивым отслеживанием дальнейшей судьбы.</p>
                    </div>
                    <div className="chivas mb-30 col-sm-12">
                        <h6><b>Если Вам понравилась Фиби, Вы можете заполнить <a href={DogsQ} download="questionnaire_dogs.docx">Анкету потенциального хозяина</a> и после направить нам её на e-mail <a href="mailto:info@budu-ryadom.ru">info@budu-ryadom.ru</a> с указанием в теме письма её имени</b></h6>
                    </div>
                </div>
            </div>
        )
    }
}